const router = [
  {
    path: '/corporation',
    name: 'corporation',
    component: () => import('@/views/corporation/corporation.vue'),
    meta: {
      title: '主体管理',
      icon: 'iconfont icon-zhutiguanli',
      hide: false,
      permissionKey: 'menu-corporation',
      showChildren: false,
    },
  },
];

export default router;

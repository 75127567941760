const router = {
  path: '/organization',
  name: 'organization',
  component: () => import('@/views/organization/organizationGraph/index.vue'),
  meta: {
    title: '组织架构',
    icon: 'iconfont icon-zuzhijiagou',
    hide: false,
    permissionKey: 'menu-admin',
    showChildren: false,
  },
};

export default router;

import type { Router, RouteRecordRaw } from 'vue-router';
import { ElMessage } from 'element-plus';
import store from '@/store/index';
import { redirectToPortal, getToken } from '@/utils/axios/axiosUtils';
import { RootRoute } from '../modules/index';

export default function createPermissionGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    // 如果是隐私政策或者是用户协议，无需鉴权（不懂为什么之前的privacyDeal和userDeal需要鉴权登陆后才能看，现在补上无需鉴权）
    if (to.path === '/login' || to.path === '/privacyPolicy' || to.path === '/userAgreement') {
      next();
      return;
    }
    const token = getToken();
    if (token) {
      const hasLogin = store.getters['app/hasLogin'];
      console.log(hasLogin);
      if (hasLogin) {
        next();
      } else {
        try {
          const permissions = await store.dispatch('app/getPermissions');
          const accessRoutes = await store.dispatch('permission/generateRoutes', permissions);
          // router.addRoute(accessRoutes);
          accessRoutes.forEach((route: RouteRecordRaw) => {
            router.addRoute(RootRoute.name, route);
          });
          next({ ...to, replace: true });
        } catch (error) {
          console.log('error', error);
          await store.dispatch('app/resetPermissions');
          redirectToPortal();
        }
      }
    } else {
      redirectToPortal();
    }
    // if (token || router.options.history.location === '/login') {
    //   const hasLogin = store.getters['app/hasLogin'];
    //   console.log('hasLogin', hasLogin);
    //   if (hasLogin) {
    //     const superAdmin = await isSuperAdmin();
    //     const permissions = superAdmin.data ? ['menu-normal', 'menu-admin'] : ['menu-normal'];
    //     // const permissions = await store.dispatch('app/getPermissions');
    //     const asyncRoutes = await store.dispatch('permission/generateRoutes', permissions);
    //     asyncRoutes.forEach((route: RouteRecordRaw) => {
    //       router.addRoute(RootRoute.name, route);
    //     });
    //     next();
    //   }
    //   // next();
    //   else {
    //     try {
    //       const permissions = await store.dispatch('app/getPermissions');
    //       const asyncRoutes = await store.dispatch('permission/generateRoutes', permissions);
    //       asyncRoutes.forEach((route: RouteRecordRaw) => {
    //         router.addRoute(RootRoute.name, route);
    //       });
    //       if (token) {
    //         next({ ...to, replace: true });
    //       } else {
    //         next();
    //       }
    //       //
    //       // 修改后;
    //     } catch (error) {
    //       await store.dispatch('app/resetPermissions');
    //       ElMessage.error(error || 'Has Error');
    //       redirectToPortal();
    //     }
    //   }
    // } else {
    //   redirectToPortal();
    // }
  });
}
